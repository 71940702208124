import * as React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Center } from "../center"

export function F06b() {
    const [current, setCurrent] = React.useState(0)
    const [direction, setDirection] = React.useState("forward")

    const emojis = ["🌕", "🌔", "🌓", "🌒", "🌑", "🌘", "🌗", "🌖"]

    const variants = {
        initial: (direction) => ({
            rotateY: direction === "forward" ? -45 : 45,
            opacity: 0,
        }),
        animate: { rotateY: 0, opacity: 1 },
        exit: (direction) => ({
            rotateY: direction === "forward" ? 45 : -45,
            opacity: 0,
        }),
    }

    return (
        <Center>
            <div
                style={{
                    width: 270,
                    height: 120,
                    position: "relative",
                }}
            >
                <AnimatePresence initial={false} custom={direction}>
                    <motion.div
                        style={{
                            width: 100,
                            height: 100,
                            position: "absolute",
                            top: 24,
                            left: 83,
                            // originZ: -100,
                            fontSize: 100,
                            textAlign: "center",
                            lineHeight: "80px",
                        }}
                        variants={variants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        key={current}
                        custom={direction} // Custom is used by the ‘initial’ and ‘exit’ variants
                        transition={{ duration: 0.5 }}
                    >
                        {emojis[current]}
                    </motion.div>
                </AnimatePresence>

                <motion.div
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 30,
                        backgroundColor: "rgba(255, 255, 255, 0.3)",
                        position: "absolute",
                        top: 35,
                        left: 0,
                        color: "#fff",
                        fontSize: 32,
                        fontWeight: 600,
                        textAlign: "center",
                        lineHeight: "45px",
                        cursor: "pointer",
                    }}
                    whileTap={{ scale: 0.9 }}
                    onTap={() => {
                        setDirection("forward") // Changing the ‘custom’ prop
                        if (current > 0) {
                            setCurrent(current - 1)
                        } else {
                            setCurrent(emojis.length - 1)
                        }
                    }}
                >
                    {"<"}
                </motion.div>
                <motion.div
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 30,
                        backgroundColor: "rgba(255, 255, 255, 0.3)",
                        position: "absolute",
                        top: 35,
                        right: 0,
                        color: "#fff",
                        fontSize: 32,
                        fontWeight: 600,
                        textAlign: "center",
                        lineHeight: "45px",
                        cursor: "pointer",
                    }}
                    whileTap={{ scale: 0.9 }}
                    onTap={() => {
                        setDirection("back") // Changing the ‘custom’ prop
                        if (current < emojis.length - 1) {
                            setCurrent(current + 1)
                        } else {
                            setCurrent(0)
                        }
                    }}
                >
                    {">"}
                </motion.div>
            </div>
        </Center>
    )
}
