import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

const variants = {
    initial: { borderRadius: 30, backgroundColor: "#fff" },
    start: { rotate: 180 },
    hoverState: { borderRadius: 75 },
    tapState: { backgroundColor: "#FFD700" },
}

export function D01VariantsFM() {
    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                variants={variants}
                initial="initial"
                animate="start"
                whileHover="hoverState"
                whileTap="tapState"
            />
        </Center>
    )
}
