import * as React from "react"
import { motion } from "framer-motion"

export function I01KeyframesFM() {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <motion.div
                style={{
                    width: 120,
                    height: 120,
                    borderRadius: 25,
                    backgroundColor: "#FA0",
                    position: "absolute",
                    left: 40,
                    top: 40,
                }}
                animate={{
                    x: [0, 200, 200, 0, 0],
                    y: [0, 0, 200, 200, 0],
                    rotate: -360,
                    backgroundColor: ["#FA0", "#60F", "#FA0"],
                }}
                transition={{ duration: 4, ease: "linear" }}
            />
        </div>
    )
}
