import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function D04Orchestration2FM() {
    const childVariants = {
        initial: { x: 50, opacity: 0 },
        start: { x: 0, opacity: 1 },
    }

    return (
        <Center>
            <motion.div
                style={{
                    width: 200,
                    height: 200,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    overflow: "hidden",
                }}
                variants={{
                    initial: { skewX: -10 },
                    start: { skewX: 0 },
                }}
                initial="initial"
                animate="start"
                transition={{
                    delayChildren: 1, // delay before start of child animations
                    staggerChildren: 0.2,
                    staggerDirection: -1,
                    when: "afterChildren",
                    delay: 1, // delay before start of this frame’s animation
                }}
            >
                <motion.div
                    style={{
                        width: 140,
                        height: 30,
                        borderRadius: 15,
                        backgroundColor: "#fa2",
                        margin: "30px auto 0 auto",
                    }}
                    variants={childVariants}
                />
                <motion.div
                    style={{
                        width: 140,
                        height: 30,
                        borderRadius: 15,
                        backgroundColor: "#fa2",
                        margin: "25px auto 0 auto",
                    }}
                    variants={childVariants}
                />
                <motion.div
                    style={{
                        width: 140,
                        height: 30,
                        borderRadius: 15,
                        backgroundColor: "#fa2",
                        margin: "25px auto",
                    }}
                    variants={childVariants}
                />
            </motion.div>
        </Center>
    )
}
