import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { Center } from "../center"

export function I09OneEasingFM() {
    const animation = useAnimation()

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                animate={animation}
                onTap={() =>
                    animation.start({
                        scale: [1, 0.5, 1.5, 1],
                    })
                }
                transition={{ duration: 3, ease: "backIn" }}
            />
        </Center>
    )
}
