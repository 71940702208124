import * as React from "react"
import { motion, useCycle } from "framer-motion"
import { Center } from "../center"

export function I08KeyframesUseCycleWhileHoverFM() {
    const [current, next] = useCycle(
        {
            borderColor: "#fff",
            scale: 1,
        },
        {
            borderColor: "#32CD32",
            scale: [1, 0.95, 1, 0.95, 1.2],
            transition: { times: [0, 0.2, 0.8, 0.9, 1], ease: "easeInOut" },
        }
    )

    return (
        <Center>
            <motion.div
                style={{
                    width: 200,
                    height: 60,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    boxShadow: "0px 0px 0px 3px #fff",
                    border: "5px solid #fff",
                    boxSizing: "border-box",
                    cursor: "pointer",
                }}
                whileHover={{
                    borderColor: ["#32CD32", "#fff", "#32CD32"],
                    transition: { duration: 0.3, times: [0.5, 0.75, 1] },
                }}
                animate={current}
                onTap={() => next()}
            />
        </Center>
    )
}
