import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function F01ConditionalRenderingFM() {
    const [isChecked, setIsChecked] = React.useState(true)

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                whileTap={{ scale: 0.95 }}
                onTap={() => setIsChecked(!isChecked)}
            >
                {isChecked && (
                    <motion.div
                        style={{
                            width: 150,
                            height: 150,
                            originX: 0,
                            originY: 1,
                        }}
                        initial={{ opacity: 0, scale: 0.1 }}
                        animate={{
                            opacity: 1,
                            scale: 1,
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="150"
                            height="150"
                        >
                            <path
                                d="M 38 74.707 L 62.647 99.353 L 108.5 53.4"
                                fill="transparent"
                                strokeWidth="20"
                                stroke="#0AF"
                                strokeLinecap="round"
                            />
                        </svg>
                    </motion.div>
                )}
            </motion.div>
        </Center>
    )
}
