import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function D03Orchestration1FM() {
    const childVariants = {
        initial: { x: 50, opacity: 0, backgroundColor: "#fa2" },
        start: { x: 0, opacity: 1 },
        tapState: { backgroundColor: "#f00" },
    }

    return (
        <Center>
            <motion.div
                style={{
                    width: 200,
                    height: 200,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    overflow: "hidden",
                    cursor: "pointer",
                }}
                variants={{
                    initial: { skewX: -10, opacity: 0 },
                    start: { skewX: 0, opacity: 1 },
                    hoverState: { scale: 1.05 },
                    tapState: {},
                }}
                initial="initial"
                animate="start"
                whileHover="hoverState"
                whileTap="tapState"
                transition={{
                    delayChildren: 0.2,
                    staggerChildren: 0.2,
                }}
            >
                <motion.div
                    style={{
                        width: 140,
                        height: 30,
                        borderRadius: 15,
                        backgroundColor: "#fa2",
                        margin: "30px auto 0 auto",
                    }}
                    variants={childVariants}
                />
                <motion.div
                    style={{
                        width: 140,
                        height: 30,
                        borderRadius: 15,
                        backgroundColor: "#fa2",
                        margin: "25px auto 0 auto",
                    }}
                    variants={childVariants}
                />
                <motion.div
                    style={{
                        width: 140,
                        height: 30,
                        borderRadius: 15,
                        backgroundColor: "#fa2",
                        margin: "25px auto",
                    }}
                    variants={childVariants}
                />
            </motion.div>
        </Center>
    )
}
