import * as React from "react"
import { motion, useCycle } from "framer-motion"
import { Center } from "../center"

export function G01TheUseCycleHookFM() {
    const [count, nextNumber] = useCycle(
        "Uno",
        "Dos",
        "Tres",
        "Cuatro",
        "Cinco",
        "Seis",
        "Siete",
        "Ocho",
        "Nueve",
        "Diez"
    )

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    color: "#333",
                    fontSize: 28,
                    display: "flex",
                    placeItems: "center",
                    placeContent: "center",
                    cursor: "pointer",
                }}
                onTap={() => nextNumber()}
            >
                {count}
            </motion.div>
        </Center>
    )
}
