import * as React from "react"
import { motion, useCycle } from "framer-motion"
import { Center } from "../center"

export function G04CyclingToValueFM() {
    const [color, nextColor] = useCycle(
        ["White", "#FFFFFF"],
        ["GhostWhite", "#F8F8FF"],
        ["Gainsboro", "#DCDCDC"],
        ["LightGray", "#D3D3D3"],
        ["Silver", "#C0C0C0"],
        ["DarkGray", "#A9A9A9"],
        ["Gray", "#808080"],
        ["DimGray", "#696969"],
        ["Black", "#000000"]
    )

    return (
        <Center>
            <motion.div
                style={{
                    width: 200,
                    height: 60,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    boxShadow: "0px 0px 0px 3px #fff",
                    color: "#333",
                    fontSize: 28,
                    display: "flex",
                    placeItems: "center",
                    placeContent: "center",
                    cursor: "pointer",
                }}
                animate={{
                    backgroundColor: color[1],
                }}
                onTap={() => nextColor()}
                onHoverEnd={() => nextColor(0)}
            >
                {color[0]}
            </motion.div>
        </Center>
    )
}
