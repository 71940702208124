import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function B02SpringSettingsFM() {
    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                }}
                animate={{ scale: 1.5 }}
                transition={{ type: "spring", damping: 3, delay: 0.3 }}
            />
        </Center>
    )
}
