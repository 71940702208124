import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function A06ComplexValuesFM() {
    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    boxShadow: "0px -300px 80px 100px rgba(255, 255, 255, .3)",
                }}
                initial={{ backgroundColor: "#fff" }}
                animate={{
                    backgroundColor: "#40F",
                    boxShadow: "0px 0px 80px 30px #fff",
                }}
                transition={{ duration: 2 }}
            />
        </Center>
    )
}
