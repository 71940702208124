import * as React from "react"
import { motion, useCycle } from "framer-motion"
import { Center } from "../center"

export function G02AnimationFM() {
    const [color, nextColor] = useCycle("#32CD32", "#f00")

    return (
        <Center>
            <motion.div
                style={{
                    width: 200,
                    height: 60,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    boxShadow: "0px 0px 0px 3px #fff",
                    border: "5px solid #32CD32",
                    boxSizing: "border-box",
                    cursor: "pointer",
                }}
                whileTap={{ scale: 0.98 }}
                initial={{
                    borderColor: "#32CD32",
                }}
                animate={{
                    borderColor: color,
                }}
                onTap={() => nextColor()}
            />
        </Center>
    )
}
