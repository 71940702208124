import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function D06CustomPropertyFM() {
    const variants = {
        initial: { scale: 0, opacity: 0 },
        start: (custom) => ({
            scale: 1,
            opacity: 1,
            transition: { delay: custom },
        }),
    }

    const size = 40
    const color = "hsl(37, 100%, 57%)"

    return (
        <Center>
            <motion.div
                style={{
                    width: 200,
                    height: 200,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    position: "relative",
                }}
            >
                <motion.div
                    style={{
                        width: 150,
                        height: 150,
                        backgroundColor: "transparent",
                        position: "absolute",
                        left: 25,
                        top: 25,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        alignContent: "space-between",
                    }}
                >
                    <motion.div
                        style={{
                            width: size,
                            height: size,
                            borderRadius: size / 2,
                            backgroundColor: color,
                        }}
                        custom={0.5}
                        variants={variants}
                        initial="initial"
                        animate="start"
                    />
                    <motion.div
                        style={{
                            width: size,
                            height: size,
                            borderRadius: size / 2,
                            backgroundColor: color,
                        }}
                        custom={0.2}
                        variants={variants}
                        initial="initial"
                        animate="start"
                    />
                    <motion.div
                        style={{
                            width: size,
                            height: size,
                            borderRadius: size / 2,
                            backgroundColor: color,
                        }}
                        custom={0.5}
                        variants={variants}
                        initial="initial"
                        animate="start"
                    />
                    <motion.div
                        style={{
                            width: size,
                            height: size,
                            borderRadius: size / 2,
                            backgroundColor: color,
                        }}
                        custom={0.2}
                        variants={variants}
                        initial="initial"
                        animate="start"
                    />
                    <motion.div
                        style={{
                            width: size,
                            height: size,
                            borderRadius: size / 2,
                            backgroundColor: color,
                        }}
                        custom={1}
                        variants={variants}
                        initial="initial"
                        animate="start"
                    />
                    <motion.div
                        style={{
                            width: size,
                            height: size,
                            borderRadius: size / 2,
                            backgroundColor: color,
                        }}
                        custom={0.2}
                        variants={variants}
                        initial="initial"
                        animate="start"
                    />
                    <motion.div
                        style={{
                            width: size,
                            height: size,
                            borderRadius: size / 2,
                            backgroundColor: color,
                        }}
                        custom={0.5}
                        variants={variants}
                        initial="initial"
                        animate="start"
                    />
                    <motion.div
                        style={{
                            width: size,
                            height: size,
                            borderRadius: size / 2,
                            backgroundColor: color,
                        }}
                        custom={0.2}
                        variants={variants}
                        initial="initial"
                        animate="start"
                    />
                    <motion.div
                        style={{
                            width: size,
                            height: size,
                            borderRadius: size / 2,
                            backgroundColor: color,
                        }}
                        custom={0.5}
                        variants={variants}
                        initial="initial"
                        animate="start"
                    />
                </motion.div>
            </motion.div>
        </Center>
    )
}
