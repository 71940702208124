import * as React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Center } from "../center"

export function F06wb() {
    const [current, setCurrent] = React.useState(0)
    const [direction, setDirection] = React.useState("forward")
    const [buttonsOpacity, setButtonsOpacity] = React.useState(1)

    const emojis = ["🌕", "🌔", "🌓", "🌒", "🌑", "🌘", "🌗", "🌖"]

    const variants = {
        initial: (direction) => ({
            rotateY: direction === "forward" ? -45 : 45,
            opacity: 0,
        }),
        animate: { rotateY: 0, opacity: 1 },
        exit: (direction) => ({
            rotateY: direction === "forward" ? 45 : -45,
            opacity: 0,
        }),
    }

    return (
        <Center>
            <div
                style={{
                    width: 270,
                    height: 120,
                    position: "relative",
                }}
            >
                <AnimatePresence
                    initial={false}
                    onExitComplete={() => {
                        setButtonsOpacity(1)
                    }}
                >
                    <motion.div
                        style={{
                            width: 140,
                            height: 120,
                            position: "absolute",
                            left: 65,
                            // originZ: -100,
                            fontSize: 100,
                            textAlign: "center",
                            lineHeight: "120px",
                        }}
                        variants={variants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        key={current}
                        custom={direction}
                        transition={{ duration: 1 }}
                    >
                        {emojis[current]}
                    </motion.div>
                </AnimatePresence>
                <motion.div
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 30,
                        backgroundColor: "rgba(255, 255, 255, 0.3)",
                        position: "absolute",
                        top: 35,
                        left: 0,
                        color: "#fff",
                        fontSize: 32,
                        fontWeight: 600,
                        textAlign: "center",
                        lineHeight: "45px",
                        cursor: "pointer",
                    }}
                    whileTap={{ scale: 0.9 }}
                    onTap={() => {
                        setDirection("forward")
                        if (current > 0) {
                            setCurrent(current - 1)
                        } else {
                            setCurrent(emojis.length - 1)
                        }
                        setButtonsOpacity(0)
                    }}
                    animate={{ opacity: buttonsOpacity }}
                >
                    {"<"}
                </motion.div>
                <motion.div
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 30,
                        backgroundColor: "rgba(255, 255, 255, 0.3)",
                        position: "absolute",
                        top: 35,
                        right: 0,
                        color: "#fff",
                        fontSize: 32,
                        fontWeight: 600,
                        textAlign: "center",
                        lineHeight: "45px",
                        cursor: "pointer",
                    }}
                    whileTap={{ scale: 0.9 }}
                    onTap={() => {
                        setDirection("back")
                        if (current < emojis.length - 1) {
                            setCurrent(current + 1)
                        } else {
                            setCurrent(0)
                        }
                        setButtonsOpacity(0)
                    }}
                    animate={{ opacity: buttonsOpacity }}
                >
                    {">"}
                </motion.div>
            </div>
        </Center>
    )
}
