import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function C01WhileHoverFM() {
    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                }}
                whileHover={{ scale: 0.8 }}
            />
        </Center>
    )
}
