import * as React from "react"
import { useState } from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function E03UsingCurrentState1FM() {
    const [degrees, setDegrees] = useState(0)

    function add45() {
        setDegrees(degrees + 45)
    }

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                animate={{ rotate: degrees }}
                onTap={add45}
            />
        </Center>
    )
}
