import * as React from "react"
import { motion } from "framer-motion"

export function I11LinearVsDifferentEasingsFM() {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            <motion.div
                style={{
                    width: 100,
                    height: 100,
                    borderRadius: "50%",
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    position: "absolute",
                    left: 70,
                    top: 70,
                }}
                animate={{
                    x: [0, 80, 80, 160],
                    rotate: [0, 0, 180, 180],
                }}
                transition={{
                    x: {
                        duration: 3,
                        yoyo: Infinity,
                        times: [0, 0.3, 0.7, 1],
                        ease: "linear",
                    },
                    rotate: {
                        duration: 3,
                        yoyo: Infinity,
                        times: [0, 0.35, 0.65, 1],
                        ease: "linear",
                    },
                }}
            >
                <motion.div
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 10,
                        backgroundColor: "#fff",
                        position: "absolute",
                        left: -50,
                        top: 25,
                    }}
                />
            </motion.div>
            <motion.div
                style={{
                    width: 100,
                    height: 100,
                    borderRadius: "50%",
                    backgroundColor: "rgba(255, 255, 255, 0.05)",
                    position: "absolute",
                    left: 70,
                    bottom: 60,
                }}
                animate={{
                    x: [0, 80, 80, 160],
                    rotate: [0, 0, 180, 180],
                }}
                transition={{
                    x: {
                        duration: 3,
                        yoyo: Infinity,
                        times: [0, 0.3, 0.7, 1],
                        ease: ["circIn", "linear", "circOut"],
                    },
                    rotate: {
                        duration: 3,
                        yoyo: Infinity,
                        times: [0, 0.35, 0.65, 1],
                        ease: ["linear", "circInOut", "linear"],
                    },
                }}
            >
                <motion.div
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 10,
                        backgroundColor: "#fff",
                        position: "absolute",
                        left: -50,
                        top: 25,
                    }}
                />
            </motion.div>
        </div>
    )
}
