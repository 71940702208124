import * as React from "react"
import { motion, useAnimation } from "framer-motion"

export function H05MultipleFramesFM() {
    const controls = useAnimation()

    async function sequence() {
        await controls.start({
            rotate: 30,
        })
        await controls.start({
            x: 50,
            y: -50,
            borderRadius: "50%",
            opacity: 0.5,
        })
        await controls.start({
            y: 50,
            rotate: -30,
        })
        await controls.start({
            x: 0,
            y: 0,
            borderRadius: 20,
            opacity: 1,
        })
        controls.start({
            rotate: 0,
        })
    }

    return (
        <motion.div
            style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
            }}
            onTap={sequence}
        >
            <motion.div
                style={{
                    width: 100,
                    height: 100,
                    borderRadius: 20,
                    backgroundColor: "#fff",
                    position: "absolute",
                    left: 75,
                    top: 75,
                }}
                animate={controls}
            />
            <motion.div
                style={{
                    width: 100,
                    height: 100,
                    borderRadius: 20,
                    backgroundColor: "#fff",
                    position: "absolute",
                    right: 75,
                    top: 75,
                }}
                animate={controls}
            />
            <motion.div
                style={{
                    width: 100,
                    height: 100,
                    borderRadius: 20,
                    backgroundColor: "#fff",
                    position: "absolute",
                    left: 75,
                    bottom: 75,
                }}
                animate={controls}
            />
            <motion.div
                style={{
                    width: 100,
                    height: 100,
                    borderRadius: 20,
                    backgroundColor: "#fff",
                    position: "absolute",
                    right: 75,
                    bottom: 75,
                }}
                animate={controls}
            />
        </motion.div>
    )
}
