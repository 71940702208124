import * as React from "react"
import { useState } from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function E02MoreStatesFM() {
    const [currentScale, setScale] = useState(1)

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                }}
                animate={{ scale: currentScale }}
                onHoverStart={() => setScale(0.8)}
                onHoverEnd={() => setScale(1)}
            />
        </Center>
    )
}
