import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { Center } from "../center"

export function H04SequenceWithPausesFM() {
    const animation = useAnimation()

    async function sequence() {
        // Move into the distance
        await animation.start({
            scale: 0.2,
            transition: { duration: 1, ease: "backInOut" },
        })
        // Pause
        await animation.start({
            scale: 0.2, // You have to animate ‘some’ value
            transition: { duration: 0.5 },
        })
        // Jump to top right corner
        await animation.start({
            x: 220,
            y: -220,
            transition: { ease: "anticipate" },
        })
        // Repositioning to right of screen
        await animation.start({
            x: 220,
            y: 0,
            scale: 0.1,
            skew: 5,
            transition: { duration: 0.5 },
        })
        // Travel across the screen
        await animation.start({
            x: -1050,
            y: 0,
            transition: { duration: 2.5, ease: "linear" },
        })
        // Repositioning (instant, no animation)
        await animation.start({
            x: 0,
            y: 650,
            scale: 4,
            skew: 0,
            rotate: -20,
            transition: { duration: 0 },
        })
        // Pause
        await animation.start({
            x: 0, // You have to animate ‘some’ value
            transition: { duration: 1 },
        })
        // Reappear
        animation.start({
            x: 0,
            y: 0,
            scale: 1,
            rotate: 0,
            transition: { duration: 2, ease: "easeOut" },
        })
    }

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                animate={animation}
                onTap={sequence}
            />
        </Center>
    )
}
