import * as React from "react"
import { motion, useAnimation } from "framer-motion"

export function H06SeparateFramesFM() {
    const controlsA = useAnimation()
    const controlsB = useAnimation()

    async function sequence() {
        controlsA.start({
            x: 150,
        })
        await controlsB.start({
            x: -150,
        })
        controlsA.start({
            y: 150,
        })
        await controlsB.start({
            y: -150,
        })
        controlsA.start({
            x: 0,
        })
        await controlsB.start({
            x: 0,
        })
        controlsA.start({
            y: 0,
        })
        await controlsB.start({
            y: 0,
        })
    }

    const transition = { ease: "easeOut" }

    return (
        <motion.div
            style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
            }}
            onTap={sequence}
        >
            <motion.div
                style={{
                    width: 100,
                    height: 100,
                    borderRadius: 20,
                    backgroundColor: "#fff",
                    position: "absolute",
                    left: 75,
                    top: 75,
                }}
                animate={controlsA}
                transition={transition}
            />
            <motion.div
                style={{
                    width: 100,
                    height: 100,
                    borderRadius: 20,
                    backgroundColor: "#fff",
                    position: "absolute",
                    right: 75,
                    bottom: 75,
                }}
                animate={controlsB}
                transition={transition}
            />
        </motion.div>
    )
}
