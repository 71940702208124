import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function D02PropagationFM() {
    const childVariants = {
        start: { rotate: -180 },
        hoverState: { borderRadius: 30 },
        tapState: { backgroundColor: "#FF0000" },
    }

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#FFFFFF",
                    display: "flex",
                    placeItems: "center",
                    placeContent: "center",
                    cursor: "pointer",
                }}
                variants={{
                    start: { rotate: 180, borderRadius: 30 },
                    hoverState: { borderRadius: 75 },
                    tapState: { backgroundColor: "#FFD700" },
                }}
                animate="start"
                whileHover="hoverState"
                whileTap="tapState"
            >
                <motion.div
                    style={{
                        width: 60,
                        height: 60,
                        borderRadius: 10,
                        backgroundColor: "#FFAA22",
                    }}
                    initial={{ backgroundColor: "#FFAA22", borderRadius: 10 }}
                    variants={childVariants}
                />
            </motion.div>
        </Center>
    )
}
