import * as React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Center } from "../center"

export function F05ExitBeforeEnterInitialFalseFM() {
    const [current, setCurrent] = React.useState(0)

    const emojis = ["⚽️", "🏀", "🏈", "⚾️", "🥎", "🎾", "🏐", "🏉", "🎱"]

    return (
        <Center>
            <div
                style={{
                    width: 260,
                    height: 120,
                    position: "relative",
                }}
            >
                <motion.div
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 30,
                        backgroundColor: "rgba(255, 255, 255, 0.3)",
                        position: "absolute",
                        top: 35,
                        left: 20,
                        color: "#fff",
                        fontSize: 32,
                        fontWeight: 600,
                        textAlign: "center",
                        lineHeight: "45px",
                        cursor: "pointer",
                    }}
                    whileTap={{ scale: 0.9 }}
                    onTap={() => {
                        if (current > 0) {
                            setCurrent(current - 1)
                        } else {
                            setCurrent(emojis.length - 1)
                        }
                    }}
                >
                    {"<"}
                </motion.div>
                <motion.div
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 30,
                        backgroundColor: "rgba(255, 255, 255, 0.3)",
                        position: "absolute",
                        top: 35,
                        right: 20,
                        color: "#fff",
                        fontSize: 32,
                        fontWeight: 600,
                        textAlign: "center",
                        lineHeight: "45px",
                        cursor: "pointer",
                    }}
                    whileTap={{ scale: 0.9 }}
                    onTap={() => {
                        if (current < emojis.length - 1) {
                            setCurrent(current + 1)
                        } else {
                            setCurrent(0)
                        }
                    }}
                >
                    {">"}
                </motion.div>
                <AnimatePresence exitBeforeEnter initial={false}>
                    <motion.div
                        style={{
                            width: 80,
                            height: 80,
                            position: "absolute",
                            top: 24,
                            left: 88,
                            fontSize: 80,
                            textAlign: "center",
                            lineHeight: "80px",
                        }}
                        initial={{ scale: 0, rotate: -180 }}
                        animate={{ scale: 1, rotate: 0 }}
                        exit={{ scale: 0, rotate: -180 }}
                        key={current}
                    >
                        {emojis[current]}
                    </motion.div>
                </AnimatePresence>
            </div>
        </Center>
    )
}
