import * as React from "react"
import { motion } from "framer-motion"

export function A02AnimatingPropertiesFM() {
    return (
        <motion.div
            style={{
                width: 150,
                height: 150,
                borderRadius: 30,
                backgroundColor: "#FFF",
                position: "absolute",
                left: 125,
                top: 125,
            }}
            initial={{ backgroundColor: "#FFF" }}
            animate={{
                left: 10,
                top: 10,
                scale: 0.5,
                rotate: 45,
                backgroundColor: "#FFD700",
            }}
            transition={{ duration: 1, delay: 0.3 }}
        />
    )
}
