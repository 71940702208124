import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function C05IncludingTransitionSettingsFM() {
    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                whileHover={{
                    backgroundColor: "#FFD700",
                    transition: { ease: "easeInOut", yoyo: Infinity },
                }}
                whileTap={{ backgroundColor: "#FFA500" }}
            />
        </Center>
    )
}
