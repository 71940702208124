import React from "react"
import { BrowserRouter as Router, useLocation } from "react-router-dom"

import { Refresh } from "./Refresh"

import { A02AnimatingPropertiesFM } from "./Framer Motion/A - animate/A02 - Animating properties"
import { A03TransformPropertiesFM } from "./Framer Motion/A - animate/A03 - Transform properties"
import { A04TransformOriginFM } from "./Framer Motion/A - animate/A04 - Transform origin"
import { A05ColorsFM } from "./Framer Motion/A - animate/A05 - Colors"
import { A06ComplexValuesFM } from "./Framer Motion/A - animate/A06 - Complex values"
import { B01TheTransitionPropertyFM } from "./Framer Motion/B - transition/B01 - The Transition property"
import { B02SpringSettingsFM } from "./Framer Motion/B - transition/B02 - Spring settings"
import { B03LoopAfterDelayFM } from "./Framer Motion/B - transition/B03 - Loop after Delay"
import { B04DelayBetweenRepetitionsFM } from "./Framer Motion/B - transition/B04 - Delay between repetitions"
import { B05YoyoWithBezierFM } from "./Framer Motion/B - transition/B05 - Yoyo with Bezier curve"
import { B06SeparateTransitionValues1FM } from "./Framer Motion/B - transition/B06 - Separate Transition values 1"
import { B07SeparateTransitionValues2FM } from "./Framer Motion/B - transition/B07 - Separate Transition values 2"
import { C01WhileHoverFM } from "./Framer Motion/C - whileHover & whileTap/C01 - WhileHover"
import { C02WhileTapFM } from "./Framer Motion/C - whileHover & whileTap/C02 - WhileTap"
import { C03WhileHoverWhileTapFM } from "./Framer Motion/C - whileHover & whileTap/C03 - WhileHover and WhileTap"
import { C04InitialFM } from "./Framer Motion/C - whileHover & whileTap/C04"
import { C05IncludingTransitionSettingsFM } from "./Framer Motion/C - whileHover & whileTap/C05"
import { C06InitialFalseFM } from "./Framer Motion/C - whileHover & whileTap/C06"
import { C06b } from "./Framer Motion/C - whileHover & whileTap/C06b"
import { D01VariantsFM } from "./Framer Motion/D - Variants/D01 - Variants"
import { D02PropagationFM } from "./Framer Motion/D - Variants/D02 - Propagation"
import { D03Orchestration1FM } from "./Framer Motion/D - Variants/D03 - Orchestration 1"
import { D04Orchestration2FM } from "./Framer Motion/D - Variants/D04 - Orchestration 2"
import { D05DynamicVariantsFM } from "./Framer Motion/D - Variants/D05 - Dynamic variants"
import { D06CustomPropertyFM } from "./Framer Motion/D - Variants/D06 - Custom property"
import { E01TheUseStateHookFM } from "./Framer Motion/E - useState()/E01 - The useState() hook"
import { E02MoreStatesFM } from "./Framer Motion/E - useState()/E02 - More states"
import { E03UsingCurrentState1FM } from "./Framer Motion/E - useState()/E03 - Using the current state 1"
import { E04UsingCurrentState2FM } from "./Framer Motion/E - useState()/E04 - Using the current state 2"
import { E05MultiplePropertiesFM } from "./Framer Motion/E - useState()/E05 - Multiple properties"
import { E06SeparateUseStateForEachPropertyFM } from "./Framer Motion/E - useState()/E06 - Separate useState() for each property"
import { E07UsingUseStateOnlyForStateFM } from "./Framer Motion/E - useState()/E07 - Using useState() only for state CC"
import { F01ConditionalRenderingFM } from "./Framer Motion/F - Animate presence/F01 - Conditional rendering"
import { F02AnimatePresenceFM } from "./Framer Motion/F - Animate presence/F02 - Animate Presence"
import { F03MultipleChildrenFM } from "./Framer Motion/F - Animate presence/F03 - Multiple children"
import { F04SameFrameDifferentKeyFM } from "./Framer Motion/F - Animate presence/F04 - Same frame, different key"
import { F04b } from "./Framer Motion/F - Animate presence/F04b"
import { F04w } from "./Framer Motion/F - Animate presence/F04w"
import { F04wb } from "./Framer Motion/F - Animate presence/F04wb"
import { F05ExitBeforeEnterInitialFalseFM } from "./Framer Motion/F - Animate presence/F05 - exitBeforeEnter & Initial false"
import { F05w } from "./Framer Motion/F - Animate presence/F05w"
import { F06OnExitCompleteFM } from "./Framer Motion/F - Animate presence/F06 - onExitComplete()"
import { F06b } from "./Framer Motion/F - Animate presence/F06b"
import { F06w } from "./Framer Motion/F - Animate presence/F06w"
import { F06wb } from "./Framer Motion/F - Animate presence/F06wb"

import { G01TheUseCycleHookFM } from "./Framer Motion/G - useCycle()/G01 - The useCycle() hook"
import { G02AnimationFM } from "./Framer Motion/G - useCycle()/G02 - Animation"
import { G03MultiplePropertiesFM } from "./Framer Motion/G - useCycle()/G03 - Multiple properties"
import { G04CyclingToValueFM } from "./Framer Motion/G - useCycle()/G04 - Cycling to a specific value"
import { G05VariantsFM } from "./Framer Motion/G - useCycle()/G05 - Variants"
import { G06ParentChildVariantsFM } from "./Framer Motion/G - useCycle()/G06 - Parent and child variants"
import { H01StartingAndStoppingFM } from "./Framer Motion/H - useAnimation()/H01 - The useAnimation() hook"
import { H02ReturnAfterDragFM } from "./Framer Motion/H - useAnimation()/H02 - Return after dragging"
import { H03SequenceFM } from "./Framer Motion/H - useAnimation()/H03 - Sequence"
import { H04SequenceWithPausesFM } from "./Framer Motion/H - useAnimation()/H04 - Sequence with pauses"
import { H05MultipleFramesFM } from "./Framer Motion/H - useAnimation()/H05 - Multiple Frames"
import { H06SeparateFramesFM } from "./Framer Motion/H - useAnimation()/H06 - Separate Frames"
import { H07DynamicStartFM } from "./Framer Motion/H - useAnimation()/H07 - Dynamic start"
import { I01KeyframesFM } from "./Framer Motion/I - Keyframes/I01 - Keyframes"
import { I02ScaleAnimationFM } from "./Framer Motion/I - Keyframes/I02 - Scale animation"
import { I03XandYFM } from "./Framer Motion/I - Keyframes/I03 - X and Y"
import { I04AddingPauseFM } from "./Framer Motion/I - Keyframes/I04 - Adding a pause"
import { I05TimesFM } from "./Framer Motion/I - Keyframes/I05 - Times"
import { I06TimesFastStartFM } from "./Framer Motion/I - Keyframes/I06 - Times - Fast start"
import { I07RecreatedSequenceFM } from "./Framer Motion/I - Keyframes/I07 - Recreated async-await sequence"
import { I08KeyframesUseCycleWhileHoverFM } from "./Framer Motion/I - Keyframes/I08 - useCycle() and WhileHover"
import { I09OneEasingFM } from "./Framer Motion/I - Keyframes/I09 - One easing"
import { I10DifferentEasingsFM } from "./Framer Motion/I - Keyframes/I10 - Different easings"
import { I11LinearVsDifferentEasingsFM } from "./Framer Motion/I - Keyframes/I11 - Linear vs. different easings"
import { J01DraggingWithUseStateFM } from "./Framer Motion/J - Tips for Overrides/J01 - Dragging with useState()"

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function returnComponent(group: string, id: number, count: number) {
    if (group === "A") {
        switch (id) {
            case 2:
                return <A02AnimatingPropertiesFM key={count} />
            case 3:
                return <A03TransformPropertiesFM key={count} />
            case 4:
                return <A04TransformOriginFM key={count} />
            case 5:
                return <A05ColorsFM key={count} />
            case 6:
                return <A06ComplexValuesFM key={count} />
        }
    } else if (group === "B") {
        switch (id) {
            case 1:
                return <B01TheTransitionPropertyFM key={count} />
            case 2:
                return <B02SpringSettingsFM key={count} />
            case 3:
                return <B03LoopAfterDelayFM key={count} />
            case 4:
                return <B04DelayBetweenRepetitionsFM key={count} />
            case 5:
                return <B05YoyoWithBezierFM key={count} />
            case 6:
                return <B06SeparateTransitionValues1FM key={count} />
            case 7:
                return <B07SeparateTransitionValues2FM key={count} />
        }
    } else if (group === "C") {
        switch (id) {
            case 1:
                return <C01WhileHoverFM key={count} />
            case 2:
                return <C02WhileTapFM key={count} />
            case 3:
                return <C03WhileHoverWhileTapFM key={count} />
            case 4:
                return <C04InitialFM key={count} />
            case 5:
                return <C05IncludingTransitionSettingsFM key={count} />
            case 6:
                return <C06InitialFalseFM key={count} />
            case 7:
                return <C06b key={count} />
        }
    } else if (group === "D") {
        switch (id) {
            case 1:
                return <D01VariantsFM key={count} />
            case 2:
                return <D02PropagationFM key={count} />
            case 3:
                return <D03Orchestration1FM key={count} />
            case 4:
                return <D04Orchestration2FM key={count} />
            case 5:
                return <D05DynamicVariantsFM key={count} />
            case 6:
                return <D06CustomPropertyFM key={count} />
        }
    } else if (group === "E") {
        switch (id) {
            case 1:
                return <E01TheUseStateHookFM key={count} />
            case 2:
                return <E02MoreStatesFM key={count} />
            case 3:
                return <E03UsingCurrentState1FM key={count} />
            case 4:
                return <E04UsingCurrentState2FM key={count} />
            case 5:
                return <E05MultiplePropertiesFM key={count} />
            case 6:
                return <E06SeparateUseStateForEachPropertyFM key={count} />
            case 7:
                return <E07UsingUseStateOnlyForStateFM key={count} />
        }
    } else if (group === "F") {
        switch (id) {
            case 1:
                return <F01ConditionalRenderingFM key={count} />
            case 2:
                return <F02AnimatePresenceFM key={count} />
            case 3:
                return <F03MultipleChildrenFM key={count} />
            case 4:
                return <F04SameFrameDifferentKeyFM key={count} />
            case 5:
                return <F05ExitBeforeEnterInitialFalseFM key={count} />
            case 6:
                return <F06OnExitCompleteFM key={count} />

            case 7:
                return <F04b key={count} />
            case 8:
                return <F06b key={count} />

            case 9:
                return <F04w key={count} />
            case 10:
                return <F04wb key={count} />
            case 11:
                return <F05w key={count} />
            case 12:
                return <F06w key={count} />
            case 13:
                return <F06wb key={count} />
        }
    } else if (group === "G") {
        switch (id) {
            case 1:
                return <G01TheUseCycleHookFM key={count} />
            case 2:
                return <G02AnimationFM key={count} />
            case 3:
                return <G03MultiplePropertiesFM key={count} />
            case 4:
                return <G04CyclingToValueFM key={count} />
            case 5:
                return <G05VariantsFM key={count} />
            case 6:
                return <G06ParentChildVariantsFM key={count} />
        }
    } else if (group === "H") {
        switch (id) {
            case 1:
                return <H01StartingAndStoppingFM key={count} />
            case 2:
                return <H02ReturnAfterDragFM key={count} />
            case 3:
                return <H03SequenceFM key={count} />
            case 4:
                return <H04SequenceWithPausesFM key={count} />
            case 5:
                return <H05MultipleFramesFM key={count} />
            case 6:
                return <H06SeparateFramesFM key={count} />
            case 7:
                return <H07DynamicStartFM key={count} />
        }
    } else if (group === "I") {
        switch (id) {
            case 1:
                return <I01KeyframesFM key={count} />
            case 2:
                return <I02ScaleAnimationFM key={count} />
            case 3:
                return <I03XandYFM key={count} />
            case 4:
                return <I04AddingPauseFM key={count} />
            case 5:
                return <I05TimesFM key={count} />
            case 6:
                return <I06TimesFastStartFM key={count} />
            case 7:
                return <I07RecreatedSequenceFM key={count} />
            case 8:
                return <I08KeyframesUseCycleWhileHoverFM key={count} />
            case 9:
                return <I09OneEasingFM key={count} />
            case 10:
                return <I10DifferentEasingsFM key={count} />
            case 11:
                return <I11LinearVsDifferentEasingsFM key={count} />
        }
    } else if (group === "J") {
        switch (id) {
            case 1:
                return <J01DraggingWithUseStateFM key={count} />
        }
    }
}

function QueryParams(props: any) {
    let query = useQuery()

    const group = query.get("id").substring(0, 1)
    const id = Number(query.get("id").substring(1))

    let background
    if (id === 1) {
        background = "#00AAFF"
    } else if (id === 2 || id === 8) {
        if (id === 8 && group === "F") {
            background = "#6600FF" // alternatief voorbeeld van 6
        } else {
            background = "#0088FF"
        }
    } else if (id === 3 || id === 9) {
        if (id === 9 && group === "F") {
            background = "#0044FF" // alternatief voorbeeld van 4
        } else {
            background = "#0066FF"
        }
    } else if (id === 4 || id === 10) {
        if (id === 10 && group === "F") {
            background = "#0044FF" // alternatief voorbeeld van 4
        } else {
            background = "#0044FF"
        }
    } else if (id === 5 || id === 11) {
        if (id === 11 && group === "F") {
            background = "#4400FF" // alternatief voorbeeld van 5
        } else {
            background = "#4400FF"
        }
    } else if (id === 6) {
        background = "#6600FF"
    } else if (id === 7) {
        if (group === "B" || group === "E" || group === "H") {
            background = "#8800FF" // extra rij
        } else if (group === "I") {
            background = "#00AAFF" // is in Keyframes, op tweede rij
        } else if (group === "C") {
            background = "#6600FF" // alternatief voorbeeld van 6
        } else if (group === "F") {
            background = "#0044FF" // alternatief voorbeeld van 4
        }
    } else if (id === 12 || id === 13) {
        background = "#6600FF" // alternatief voorbeeld van 6 (in F)
    }

    let refresh = null
    if (group === "A") {
        if (id === 2 || id === 3 || id === 4 || id === 5 || id === 6) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "B") {
        if (
            id === 1 ||
            id === 2 ||
            id === 3 ||
            id === 4 ||
            id === 5 ||
            id === 6 ||
            id === 7
        ) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "C") {
        if (id === 4 || id === 5 || id === 6 || id === 7) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "D") {
        if (
            id === 1 ||
            id === 2 ||
            id === 3 ||
            id === 4 ||
            id === 5 ||
            id === 6
        ) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "E") {
        if (id === 1 || id === 4 || id === 6 || id === 7) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "F") {
        if (
            id === 1 ||
            id === 2 ||
            id === 3 ||
            id === 4 ||
            id === 5 ||
            id === 6 ||
            id === 7 ||
            id === 8 ||
            id === 9 ||
            id === 10 ||
            id === 11 ||
            id === 12 ||
            id === 13
        ) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "G") {
        if (id === 1 || id === 2 || id === 3 || id === 5 || id === 6) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "H") {
        if (id === 1) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "I") {
        if (id === 1 || id === 2) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    } else if (group === "J") {
        if (id === 1) {
            refresh = (
                <Refresh onClick={() => props.setCount(props.count + 1)} />
            )
        }
    }

    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                background: background,
            }}
        >
            {refresh}
            {returnComponent(group, id, props.count)}
        </div>
    )
}

export function App() {
    const [count, setCount] = React.useState(0)

    return (
        <>
            <Router>
                <QueryParams count={count} setCount={setCount} />
            </Router>
        </>
    )
}

export default App
