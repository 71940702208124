import * as React from "react"
import { motion, useCycle } from "framer-motion"
import { Center } from "../center"

export function G05VariantsFM() {
    const [cycle, next] = useCycle(
        "Center",
        "TopLeft",
        "TopRight",
        "BottomRight",
        "BottomLeft"
    )

    const variants = {
        Center: { x: 0, y: 0 },
        TopLeft: { x: -100, y: -100 },
        TopRight: { x: 100, y: -100 },
        BottomRight: { x: 100, y: 100 },
        BottomLeft: { x: -100, y: 100 },
    }

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                animate={cycle}
                variants={variants}
                onHoverStart={() => next()}
            />
        </Center>
    )
}
