import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function C02WhileTapFM() {
    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                whileTap={{ scale: 0.8 }}
                transition={{ duration: 0.5 }}
            />
        </Center>
    )
}
