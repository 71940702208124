import * as React from "react"
import { motion } from "framer-motion"

export function A04TransformOriginFM() {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                placeItems: "center",
                placeContent: "center",
                perspective: 800,
            }}
        >
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    originY: 0,
                }}
                animate={{ rotateX: 360 }}
                transition={{ duration: 0.8, delay: 0.3 }}
            />
        </div>
    )
}
