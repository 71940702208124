import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function D05DynamicVariantsFM() {
    const childVariants = {
        initial: () => ({
            scale: 0,
            opacity: 0,
            backgroundColor: `hsl(${Math.floor(Math.random() * 360)},80%,50%)`,
        }),
        start: {
            scale: 1,
            opacity: 1,
        },
    }

    const size = 40
    const margin = 25
    const gap = 15
    const color = "hsl(37, 100%, 57%)"

    return (
        <Center>
            <motion.div
                style={{
                    width: 200,
                    height: 200,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    position: "relative",
                }}
                variants={{
                    initial: {},
                    start: {},
                }}
                initial="initial"
                animate="start"
                transition={{
                    delayChildren: 0.1,
                    staggerChildren: 0.1,
                }}
            >
                <motion.div
                    style={{
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                        backgroundColor: color,
                        position: "absolute",
                        left: margin,
                        top: margin,
                    }}
                    variants={childVariants}
                />
                <motion.div
                    style={{
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                        backgroundColor: color,
                        position: "absolute",
                        left: margin + size + gap,
                        top: margin,
                    }}
                    variants={childVariants}
                />
                <motion.div
                    style={{
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                        backgroundColor: color,
                        position: "absolute",
                        left: margin + size * 2 + gap * 2,
                        top: margin,
                    }}
                    variants={childVariants}
                />
                <motion.div
                    style={{
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                        backgroundColor: color,
                        position: "absolute",
                        left: margin + size * 2 + gap * 2,
                        top: margin + size + gap,
                    }}
                    variants={childVariants}
                />
                <motion.div
                    style={{
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                        backgroundColor: color,
                        position: "absolute",
                        left: margin + size * 2 + gap * 2,
                        top: margin + size * 2 + gap * 2,
                    }}
                    variants={childVariants}
                />
                <motion.div
                    style={{
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                        backgroundColor: color,
                        position: "absolute",
                        left: margin + size + gap,
                        top: margin + size * 2 + gap * 2,
                    }}
                    variants={childVariants}
                />
                <motion.div
                    style={{
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                        backgroundColor: color,
                        position: "absolute",
                        left: margin,
                        top: margin + size * 2 + gap * 2,
                    }}
                    variants={childVariants}
                />
                <motion.div
                    style={{
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                        backgroundColor: color,
                        position: "absolute",
                        left: margin,
                        top: margin + size + gap,
                    }}
                    variants={childVariants}
                />
                <motion.div
                    style={{
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                        backgroundColor: color,
                        position: "absolute",
                        left: margin + size + gap,
                        top: margin + size + gap,
                    }}
                    variants={childVariants}
                />
            </motion.div>
        </Center>
    )
}
