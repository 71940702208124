import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function C04InitialFM() {
    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                initial={{ opacity: 0, borderRadius: 30 }}
                animate={{ opacity: 1 }}
                whileHover={{ borderRadius: 75 }}
                whileTap={{ backgroundColor: "#FFD700" }}
            />
        </Center>
    )
}
