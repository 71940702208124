import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { Center } from "../center"

export function H02ReturnAfterDragFM() {
    const controls = useAnimation()

    let timeout: number
    timeout = window.setTimeout(function () {})

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "grab",
                }}
                // Dragging
                drag
                dragConstraints={{
                    top: -125,
                    right: 125,
                    bottom: 125,
                    left: -125,
                }}
                // Animation
                animate={controls}
                onDragEnd={() => {
                    timeout = window.setTimeout(() => {
                        controls.start({ x: 0, y: 0 })
                    }, 2000)
                }}
                onDragStart={() => clearTimeout(timeout)}
                whileTap={{ cursor: "grabbing" }}
            />
        </Center>
    )
}
