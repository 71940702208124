import * as React from "react"
import { motion } from "framer-motion"
import { Center } from "../center"

export function A03TransformPropertiesFM() {
    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                }}
                animate={{
                    scaleY: 0.5,
                    scaleX: 2,
                    skewX: -6,
                    y: -120,
                }}
                transition={{ duration: 1, delay: 0.3 }}
            />
        </Center>
    )
}
