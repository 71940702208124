import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { Center } from "../center"

export function I07RecreatedSequenceFM() {
    const animation = useAnimation()

    return (
        <Center>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    cursor: "pointer",
                }}
                animate={animation}
                onTap={() =>
                    animation.start({
                        scaleX: [1, 0.2, 0.2, 1],
                        scaleY: [1, 1, 0.2, 1],
                    })
                }
                transition={{
                    duration: 2.3,
                    times: [0, 0.43, 0.86, 1],
                    ease: "backInOut",
                }}
            />
        </Center>
    )
}
