import * as React from "react"
import { motion } from "framer-motion"

export function A05ColorsFM() {
    return (
        <>
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    position: "absolute",
                    left: 40,
                    top: 40,
                }}
                initial={{ backgroundColor: "#fff" }}
                animate={{ backgroundColor: "#FF6347" }}
                transition={{ duration: 1 }}
            />
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    position: "absolute",
                    right: 40,
                    bottom: 40,
                }}
                initial={{ backgroundColor: "#fff" }}
                animate={{ backgroundColor: "#B6C" }}
                transition={{ duration: 1, delay: 2 }}
            />
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 30,
                    backgroundColor: "#fff",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    margin: "-75px 0 0 -75px",
                }}
                initial={{ backgroundColor: "#fff" }}
                animate={{ backgroundColor: "rgba(153, 204, 102, 0.8)" }}
                transition={{ duration: 1, delay: 1 }}
            />
        </>
    )
}
